import React, { useEffect, useState } from "react";
import { baseUrl } from "../../constant/constant";

// Utility function to parse work hours
const parseWorkHours = (workHours) => {
  try {
    const parsedHours = JSON.parse(workHours);
    return parsedHours;
  } catch (error) {
    console.error("Error parsing work hours:", error);
    return {};
  }
};

function Dashboard() {
  const [timesheets, setTimesheets] = useState([]);
  const [locationFilter, setLocationFilter] = useState("All");
  const [workerFilter, setWorkerFilter] = useState("All");

  const fetchTimesheets = async () => {
    try {
      const response = await fetch(baseUrl + "timeSheet", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data); // Log the data to the console

      // Convert the fetched data to match the expected format
      const formattedData = data.map((item) => ({
        employee_id: item.employeeName,
        location: item.location,
        work_hours: parseWorkHours(item.workHours),
        date: item.date,
        comments: item.comments,
        photo_url: baseUrl + item.photo,
        created_at: new Date(item.created_at).toLocaleDateString(),
      }));

      setTimesheets(formattedData);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
    }
  };

  useEffect(() => {
    fetchTimesheets();
  }, []);

  const handleLocationFilterChange = (event) => {
    setLocationFilter(event.target.value);
  };

  const handleWorkerFilterChange = (event) => {
    setWorkerFilter(event.target.value);
  };

  const filteredTimesheets = timesheets.filter((ts) => {
    const locationMatch =
      locationFilter === "All" || ts.location === locationFilter;
    const workerMatch =
      workerFilter === "All" || ts.employee_id === workerFilter;
    return locationMatch && workerMatch;
  });

  return (
    <div className="container mx-auto mt-10 p-4">
      <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">
        BETAPI Evidencija Radnih Sati
      </h1>

      {/* Filters */}
      <div className="mb-8 flex flex-col md:flex-row justify-center items-center gap-6">
        <div className="flex flex-col items-start w-full md:w-1/2 lg:w-1/3">
          <label
            htmlFor="locationFilter"
            className="font-semibold mb-2 text-gray-700"
          >
            Po Lokaciji Bauštele:
          </label>
          <select
            id="locationFilter"
            className="p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            value={locationFilter}
            onChange={handleLocationFilterChange}
          >
            <option value="All">All</option>
            <option value="Site A">Site A</option>
            <option value="Site B">Site B</option>
            <option value="Site C">Site C</option>
          </select>
        </div>

        <div className="flex flex-col items-start w-full md:w-1/2 lg:w-1/3">
          <label
            htmlFor="workerFilter"
            className="font-semibold mb-2 text-gray-700"
          >
            Po Imenu Radnika:
          </label>
          <select
            id="workerFilter"
            className="p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
            value={workerFilter}
            onChange={handleWorkerFilterChange}
          >
            <option value="All">All</option>
            {timesheets.map((ts) => (
              <option key={ts.employee_id} value={ts.employee_id}>
                {ts.employee_id}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg border border-gray-200">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-left">Ime Radnika</th>
              <th className="py-3 px-6 text-left">Lokacija Bauštele</th>
              <th className="py-3 px-6 text-left">Sati</th>
              <th className="py-3 px-6 text-left">date</th>
              <th className="py-3 px-6 text-left">Opis Posla</th>
              <th className="py-3 px-6 text-left">Slika Izvještaja</th>
              <th className="py-3 px-6 text-left">Predano Na Dan</th>
            </tr>
          </thead>
          <tbody>
            {filteredTimesheets.map((ts) => (
              <tr
                key={ts.employee_id}
                className="border-t border-gray-300 hover:bg-gray-100"
              >
                <td className="py-4 px-6">{ts.employee_id}</td>
                <td className="py-4 px-6">{ts.location}</td>
                <td className="py-4 px-6">
                  <ul className="list-none space-y-1">
                    {Object.entries(ts.work_hours).map(([day, hours]) => 
                      hours.start && hours.end ? (
                        <li key={day}>
                          <strong>{day}:</strong> {hours.start} - {hours.end}
                        </li>
                      ) : null
                    )}
                  </ul>
                </td>
                <td className="py-4 px-6">{ts.date}</td>
                <td className="py-4 px-6">{ts.comments}</td>
                <td className="py-4 px-6">
                  <img
                    src={ts.photo_url}
                    alt="Report"
                    className="w-20 h-20 object-cover rounded"
                  />
                </td>
                <td className="py-4 px-6">{ts.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
