import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../constant/constant';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LeaveRequest() {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch leave request data using fetch API
  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const response = await fetch(baseUrl + "sickLeave", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Pass the token in the headers
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch leave requests');
        }
        const data = await response.json();
        setLeaveRequests(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchLeaveRequests();
  }, []);

  const handleApprove = async (id) => {
    try {
      const response = await fetch(`${baseUrl}sickLeave/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Pass the token in the headers
        },
      });

      if (!response.ok) {
        throw new Error('Failed to approve leave request');
      }

      const updatedRequest = await response.json();
      setLeaveRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === id ? updatedRequest.sickLeave : request
        )
      );
      toast.success('Leave request approved successfully!');
    } catch (error) {
      console.log(error);
      toast.error('Failed to approve leave request. Please try again.');
    }
  };

  if (loading) {
    return <div className="text-center text-xl font-semibold mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 text-lg mt-10">{error}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl font-bold text-center mb-8">Leave Requests</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-green-500 text-white">
              <th className="px-4 py-2 border border-gray-300">ID</th>
              <th className="px-4 py-2 border border-gray-300">Date</th>
              <th className="px-4 py-2 border border-gray-300">File</th>
              <th className="px-4 py-2 border border-gray-300">Approved</th>
              <th className="px-4 py-2 border border-gray-300">Action</th> {/* New Action column */}
            </tr>
          </thead>
          <tbody>
            {leaveRequests.map((request) => (
              <tr key={request.id} className="even:bg-gray-100 hover:bg-gray-200">
                <td className="px-4 py-2 border border-gray-300">{request.id}</td>
                <td className="px-4 py-2 border border-gray-300">
                  {new Date(request.date).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <a
                    href={`https://your-server.com/files/${request.file}`} // Replace with your server URL
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {request.file}
                  </a>
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  {request.approved ? (
                    <span className="text-green-600 font-semibold">Approved</span>
                  ) : (
                    <span className="text-yellow-500 font-semibold">Pending</span>
                  )}
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  {!request.approved && (
                    <button
                      onClick={() => handleApprove(request.id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      Approve
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LeaveRequest;
