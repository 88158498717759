
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { FaTachometerAlt, FaUserPlus, FaUsers, FaSignOutAlt, FaFileAlt } from "react-icons/fa"; // Import icons

const Sidebar = ({ isVisible }) => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const location = useLocation(); // To get the current route

  const handleLogout = () => {
    logout();
  };

  return (
    isAuthenticated && (
      <nav
        className={`pt-[100px] w-64 bg-gray-900 min-h-screen text-white fixed top-0 left-0 transition-transform duration-300 ${
          isVisible ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-8 text-center text-blue-400">
            Admin Panel
          </h1>
          <ul className="space-y-6">
            <li>
              <Link
                to="/"
                className={`flex items-center space-x-4 p-3 rounded-lg transition-colors duration-300 ${
                  location.pathname === "/"
                    ? "bg-blue-700 text-white"
                    : "hover:bg-gray-700 text-gray-300"
                }`}
              >
                <FaTachometerAlt className="text-blue-400" /> {/* Dashboard Icon */}
                <span className="text-lg font-medium">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/add-employee"
                className={`flex items-center space-x-4 p-3 rounded-lg transition-colors duration-300 ${
                  location.pathname === "/add-employee"
                    ? "bg-blue-700 text-white"
                    : "hover:bg-gray-700 text-gray-300"
                }`}
              >
                <FaUserPlus className="text-green-400" /> {/* Add Employee Icon */}
                <span className="text-lg font-medium">Add Employee</span>
              </Link>
            </li>
            <li>
              <Link
                to="/employ-list"
                className={`flex items-center space-x-4 p-3 rounded-lg transition-colors duration-300 ${
                  location.pathname === "/employ-list"
                    ? "bg-blue-700 text-white"
                    : "hover:bg-gray-700 text-gray-300"
                }`}
              >
                <FaUsers className="text-yellow-400" /> {/* Employee List Icon */}
                <span className="text-lg font-medium">Employee List</span>
              </Link>
            </li>
            <li>
              <Link
                to="/leave-request"
                className={`flex items-center space-x-4 p-3 rounded-lg transition-colors duration-300 ${
                  location.pathname === "/leave-request"
                    ? "bg-blue-700 text-white"
                    : "hover:bg-gray-700 text-gray-300"
                }`}
              >
                <FaFileAlt className="text-purple-400" /> {/* Leave Request Icon */}
                <span className="text-lg font-medium">Leave Request</span>
              </Link>
            </li>
            <li>
              <button
                onClick={handleLogout}
                className="w-full text-left flex items-center space-x-4 p-3 hover:bg-red-700 rounded-lg transition-colors duration-300 text-gray-300"
              >
                <FaSignOutAlt className="text-red-400" /> {/* Logout Icon */}
                <span className="text-lg font-medium">Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    )
  );
};

export default Sidebar;
