import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom";
import CreateLogin from "./pages/createLogin";
import Dashboard from "./pages/Dashboard";
import EmployList from "./pages/EmployList"; // Import the new page
import { AuthContext, AuthProvider } from "./context/AuthContext";
import menu from "../src/images/menu.svg";
import Sidebar from "./Sidebar/Sidebar";
import AdminLogin from "./pages/AdminLogin";
import LeaveRequest from "./pages/LeaveRequest";








const MainContent = ({ isSidebarVisible }) => (
  <div
    className={`flex-1 p-8 transition-all duration-300 ${
      isSidebarVisible ? "ml-64" : "ml-0"
    }`}
  >
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/add-employee" element={<CreateLogin />} />
      <Route path="/employ-list" element={<EmployList />} />
      <Route path="/leave-request" element={<LeaveRequest />} />
      <Route path="*" element={<AdminLogin to="/" />} />
    </Routes>
  </div>
);



const App = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const { isAuthenticated } = useContext(AuthContext);
  // const Navigate= useNavigate()

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  return (
    <Router>
      <div className="flex">
        {/* Show sidebar only if authenticated */}
        {isAuthenticated && <Sidebar isVisible={isSidebarVisible} />}
        <div className="flex-1 flex flex-col">
          {isAuthenticated && (
            <button
              onClick={toggleSidebar}
              className="mb-5 p-4 bg-blue-500 text-white fixed top-4 left-4 z-50 rounded-md shadow-md hover:bg-blue-600 transition duration-300 flex items-center justify-center"
            >
              <img src={menu} alt="Menu Icon" className="w-6 h-6" />
            </button>
          )}

          {/* Conditionally show routes based on authentication */}
          {isAuthenticated ? (
            <MainContent isSidebarVisible={isSidebarVisible} />
          ) : (
            <Routes>
              <Route path="/admin" element={<AdminLogin />} />
              <Route path="*" element={<AdminLogin to="/admin" />} />
            </Routes>
          )}
        </div>
      </div>
    </Router>
  );
};


export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
